import React from 'react';
import inl from './inl-link.png'
import './App.css';
import Helmet from 'react-helmet';

function App() {
  return (
    <>
    <div className="container">
          <div className="inl-load medium" style={{margin: 'auto'}}>
              <div className="ring ring-1"></div>
              <div className="ring ring-2"></div>
              <div className="ring ring-3"></div>
              <img className="ring logo" src={inl} />
          </div>
          <div className="title">iEducation Suite</div>
          <div className="subtitle">Imagine No Limit Technology</div>
          <div className="text">
            <strong>Our Product</strong><br />
            <a href="https://exam.inl.in.th" title="ระบบจัดการสอบ">iExam</a>{' | '}
            <a href="https://iedu.inl.in.th/portal/" title="ระบบงานรับสมัครนักเรียน">iFreshy</a>{' | '}
            <a href="https://club.inl.in.th/" title="ระบบงานกิจกรรมพัฒนาู้เรียน (ชุมนุม)">Club</a>
          </div>
          <div className="text">
            <strong>Contact Us</strong><br />
            <a href="https://www.facebook.com/INLTech/">Facebook Page</a>{' | '}
            <a href="mailto:techno@inl.in.th">E-Mail</a>
          </div>
        </div>
    <div className="App">
      <Helmet title="iEducation Suite | Imagine No Limit Technology" />
    </div>
    </>
  );
}

export default App;
